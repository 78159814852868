<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="AccCatId"
        item-text="AccCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="AccCatFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: ['value', 'accCatIdParent', 'label', 'multiple', 'readonly', 'defValue', 'smallChips', 'deletableChips', 'selectOnReadonly'],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      accCatIdParent() {
        this.$refs.AccCatFindCtrl.cachedItems = [];
        this.loadItems('');
      },
      value() {
        if (this.value != null) {
          this.loadItems('');
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            CampoBusqueda: 'AccCatNom',
            ValorBuscar: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            AccCatIdParent: this.accCatIdParent
        };

        var valorActual = this.value;
        var valorDefecto = this.defValue;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/AccCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
            result.data.EntsList.forEach(element => {
              var itemObject = { AccCatId: element.AccCatId, AccCatNom: element.AccCatNom };
              this.itemsList.push(itemObject);
            });


            if (valorActual != null) {
              // if (valorActual.length == null) {
              if (!Array.isArray(valorActual)) {  // es un objeto
                  if (valorActual.AccCatId != null) {
                    if (!this.itemsList.some(itm=>itm.AccCatId === valorActual.AccCatId)) {
                      this.itemsList.push(valorActual);
                    }
                  }
                  else if (valorDefecto != null) {
                    var itmDefault = this.itemsList.find(itm=>itm.AccCatId === parseInt(valorDefecto))
  
                    if (!this.itemsList.some(itm=>itm.AccCatId === parseInt(valorDefecto))) {
                      this.itemsList.push(itmDefault);
                    }
                    this.value = itmDefault;
                    this.$emit('input', this.value);
                  }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.AccCatId === element.AccCatId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        })
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.AccCatFindCtrl.isMenuActive) {
            this.$refs.AccCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.AccCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.AccCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems('');
      // if (this.accCatIdParent != null) {
      // }
    },
  }
</script>