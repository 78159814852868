<template>
  <div id="fondo">
    <div id="clock">
        <p class="date">{{ date }}</p>
        <p class="time">{{ time }}</p>
        <p class="weekDay">{{weekDay}}</p>
        <!-- <p class="text">DIGITAL CLOCK with Vue.js</p> -->
    </div>
  </div>
</template>
<script>
  export default {
      data: () => ({
        time: '',
        date: '',
        weekDay: '',
      }),
      methods: {
       updateTime() {
          var cd = new Date();
          var week = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'];
          // var week = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
          // var week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
          // this.timerID = setInterval(this.updateTime, 1000);

          // this.date =  this.zeroPadding(cd.getDate(), 2) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getFullYear(), 4) + ' ' + week[cd.getDay()];
          this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
          this.date =  this.zeroPadding(cd.getDate(), 2) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getFullYear(), 4);
          this.weekDay = week[cd.getDay()];
          // this.date = this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getDate(), 2) + ' ' + week[cd.getDay()];
        },
        zeroPadding(num, digit) {
            var zero = '';
            for(var i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        }
      },
      mounted: function() {
        this.updateTime();
        setInterval(this.updateTime, 1000);
      }
  }
</script>

<style scoped lang="scss">
  #fondo{
    // margin-left: 0;
    // margin-right: 0;
    // margin-top: 0;
    // margin-bottom: 0;
    // margin: auto;
    background-color: #0f3854;
    background-image: radial-gradient(ellipse at center,  #0a2e38  20%, #000000 70%);      
    // background-image: radial-gradient(ellipse at center,  #0a2e38  0%, #000000 70%);      
    // background-size: 100%;

    width:100%;
    height:100%;
    background-color: #0f3854;
    text-align: center;
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    border:5px solid black;
    border-radius: 7px;
  };
  #clock {
      // background:transparent;
      font-family: 'Share Tech Mono', monospace;
      color: #ffffff;
      // text-align: center;
      // position: absolute;
      //  left: 50%;
      //  top: 50%;
      // height: 100%;
      // width: 100%;
      // display:flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      // transform: translate(-50%, -50%);
      // margin: auto;

      color: #daf6ff;
      text-shadow: 0 0 20px rgba(10, 175, 230, 1),  0 0 20px rgba(10, 175, 230, 0);
      // background-color: #0f3854;
      // background: radial-gradient(ellipse at center,  #0a2e38  0%, #000000 70%);      
      .time {
          letter-spacing: 0.05em;
          font-size: 60px;
          padding: 5px 0;
      }
      .date {
          letter-spacing: 0.1em;
          font-size: 24px;
      }
      .weekDay {
          letter-spacing: 0.1em;
          font-size: 24px;
      }
      .text {
          letter-spacing: 0.1em;
          font-size: 12px;
          padding: 20px 0 0;
      }
  }
</style>