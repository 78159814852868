<template>
  <v-form
    ref="form"
    v-model="valid"
    style="paddingTop:40px; margin:auto"
    autocomplete="off"
  >
    <div class="text-center">
      <h2>Fichaje de empleados</h2>
      <h4>Utilice su cuenta para fichar</h4>
    </div>

    <br>
      <hr style="margin-left:10px; margin-right:10px">
    <br>

    <v-container>
      <v-row no-gutters align="center" class="mt-n5" justify="center">
        <v-col style="width: 100%; max-width: 700px" cols="auto" class="pa-2">
          <Clock></Clock>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center">
        <v-col style="width: 100%; max-width: 700px" cols="auto" class="pa-2">
          <div class="text-center pa-4 mb-4" style="border-width: thin; border-style: solid; border-color: grey lighten-2; border-radius: 7px; height: 100%">
            <v-text-field
              outlined=""
              v-model="usuario"
              :rules="usuarioRules"
              label="Usuario"
              required
              style="width:300px; marginTop:20px; margin-left:auto; margin-right:auto"
              prepend-icon="mdi-account"
              type="text"
              class="mt-0"
            ></v-text-field>

            <v-text-field
              outlined=""
              v-model="password"
              :rules="passwordRules"
              label="Contraseña"
              required
              style="width:300px; margin-left:auto; margin-right:auto"
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>

            <v-btn
              color="primary"
              @click="enviar"
              style="width: 200px"
              >
              Validar usuario
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" justify="center">
        <v-col style="width: 100%; max-width: 700px" cols="auto" class="pa-2">
          <div class="text-center pa-4" style="border-width: thin; border-style: solid; border-color: grey lighten-2; border-radius: 7px; height: 100%">
            <v-text-field
              v-model="perNomFichar"
              label="Nombre de empleado"
              outlined
              dense
              readonly
              required
              :rules="perNomFicharRules"
              >
            </v-text-field>
            <v-overflow-btn
              v-model="tipo"
              :items="itemsTipo"
              item-value="AccTipId"
              item-text="AccTipNom"
              label="Tipo"
              outlined
              dense
              style="width:300px; margin-left:auto; margin-right:auto"
              return-object
              ref="tipoCtrl"
              required
              :rules="tipoRules"
            ></v-overflow-btn>
              <!-- @change="procesarTipo" -->

            <AccCatFind class="mb-n3" ref="accCatCtrl" label="Motivo" :accCatIdParent="1" v-model="accCat" :readonly="true" :selectOnReadonly="true"></AccCatFind>

            <v-btn
              color="success"
              :disabled="perNomFichar==''"
              @click="validate"
              style="width: 200px"
              >
              Fichar
            </v-btn>
              <!-- style="width: 300px; text-transform: none" -->
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" justify="center">
        <v-col style="width: 100%; " cols="auto" class="pa-2">
          <v-alert text>
            <div style="text-align: center"><h4>RESULTADO DEL ULTIMO FICHAJE</h4></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[0]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[1]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[2]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[3]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[4]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[5]}}</h5></div>
            <div style="text-align: center"><h5>{{resultadoUltimoFichaje[6]}}</h5></div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import Clock from "../components/Clock.vue";
  import axios from "axios";
  import { mapState } from "vuex";
  import AccCatFind from "../components/AccCatFind.vue";
  export default {
    components:{
      Clock,
      AccCatFind
    },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'per', 'emp', 'esDir', 'dirId', 'dir'])
    },
    data: () => ({
      deshabilitado: true,
      valid: true,
      usuario: '',
      usuarioRules: [
        v => !!v || 'El usuario es obligatorio',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'La contraseña es obligatoria',
      ],
      itemsTipo: [],
      tipo:{},
      tipoRules: [
        v => !!v.AccTipId || 'El tipo de fichaje es obligatorio'
      ],
      accCat: {},
      accCatRules: [
        v => !!v.AccCatId || 'El motivo es obligatorio'
      ],
      empresa: {},
      tituloPrincipal: '',
      tituloSecundario: '',
      resultadoUltimoFichaje: [],
      ultimoFichaje: {},
      perNomFichar: '',
      perNomFicharRules: [
        v => !!v || 'El nombre de empleado es obligatorio'
      ]
    }),
    methods: {
      validate () {
        this.$refs.form.validate()
        if (this.valid)
        {
            this.fichar();
        }
      },
      enviar () {
        var loginObj = { UserName: this.usuario, Password: this.password, LoginUse: "FICHAJE" };
        var loginJson = JSON.stringify(loginObj);

         axios({ method: "POST", "url": this.urlRaiz + "/api/login/authenticate", "data": loginJson, "headers": { "content-type": "application/json" } })
            .then(result => {
              if (result.status >= 200 && result.status <= 299) {
                var controllerParameters = {
                    Action: 'GET_DATA_LIST_PAGINATED',
                    CampoBusqueda: '',
                    ValorBuscar: '',
                    NumRegsPag: 1,
                    NumPag: 1,
                    EmpId: this.empId,
                    AccCat1Id: 1,
                    UsrLogin: this.usuario,
                    DirId: this.dirId,
                    desdeFecha: new Date('2020-01-01'),
                    hastaFecha: new Date(),
                    CampoOrder: 'AccTmp DESC'
                };

                // var controllerParameters = {
                //     Action: "SAVE_ACC",
                //     UsrLogin: this.usuario,
                //     AlmPerId: this.perId,
                //     DirId: this.dirId
                // };       

                var AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/acc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result2 => {
                    if (result2.status >= 200 && result2.status <= 299) {
                        // alert("Fichaje realizado correctamente");
                        // this.resultadoUltimoFichaje = result2.data;
                        if (typeof(result2.data.EntsList[0]) != 'undefined' && typeof(result2.data.EntsList[0].AccTipId != 'undefined')) {
                          this.ultimoFichaje = result2.data.EntsList[0];
                          // if (result2.data.EntsList[0].AccTipId == 1) {
                          if (this.ultimoFichaje.AccTipId == 1) {
                            this.tipo = this.itemsTipo[1];
                          }
                          else {
                            this.tipo = this.itemsTipo[0];
                          }
                        }
                        else {
                          this.tipo = this.itemsTipo[0];
                        }
                        this.perNomFichar = result2.data.PerFic.PerNom;
                        this.resultadoUltimoFichaje = [];
                    }
                    else {
                        alert('Ha habido un error ' + result2.status + ' en el fichaje: ' + result2.statusText);
                    }
                })
                .catch(error2=>{
                    alert(error2);
                })
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                alert('Usuario o contraseña incorrecto');
              }
              else {
                alert(error);
              }
            })
      },
      fichar() {
        var controllerParameters = {
            Action: 'SAVE_ACC',
            UsrLogin: this.usuario,
            AlmPerId: this.perId,
            DirId: this.dirId,
            AccTipId: this.tipo.AccTipId,
            AccCatId: this.accCat.AccCatId
        };

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/acc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result2 => {
            if (result2.status >= 200 && result2.status <= 299) {
                alert('Fichaje realizado correctamente');
                this.usuario = '';
                this.password = '';
                this.resultadoUltimoFichaje = result2.data;
                this.perNomFichar = '';
                this.tipo = {};
                this.accCatRules = {};
                this.$refs.form.resetValidation();
            }
            else {
                alert('Ha habido un error ' + result2.status + ' en el fichaje: ' + result2.statusText);
            }
        })
        .catch(error2=>{
            alert(error2);
        })
      },
      cargarTipo() {
        this.itemsTipo = [
          { 'AccTipId': 1, 'AccTipNom': 'ENTRADA' },
          { 'AccTipId': 2, 'AccTipNom': 'SALIDA' }
        ]
      },
    },
    mounted() {
      this.cargarTipo();
    }
  }
</script>

